import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/apps/sergei20/src/components/LayoutInner/LayoutInner.tsx";
import { NotFoundFrame } from '../../src/components/NotFoundFrame';
import { Container } from '../../src/components/Container';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Container mdxType="Container">
    <NotFoundFrame mdxType="NotFoundFrame" />
    </Container>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      